import React from "react";

import _ from "lodash";
import { v4 } from "uuid";
import "./style.scss";

import IMAGES from "../../../../images/iconSet";
import Checkbox from "../../../inputs/checkbox";
import Radio from "../../../inputs/radio";
import { Button } from "react-bootstrap";

class ListEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      dragStartY: null,
      draggedItem: undefined,
      draggedHtml: undefined,
    };

    this.uploadExcel = React.createRef();
  }

  onChange = () => {
    this.props.onChange(this.state.data);
  };

  onInitialValueChange = (e, i) => {
    if (this.props.type === "radio") {
      this.state.data.forEach((item) => {
        item.initialValue = false;
      });
    }
    this.state.data[i].initialValue = e.target.checked;
    this.setState(this.state, this.onChange);
  };

  onChangeText = (e, i) => {
    const { type } = this.props;

    let value = e.target.value;

    const matchMultiSpaces = value.match(/\s{2,}/);

    if (matchMultiSpaces?.[0]) {
      value = value.replace(matchMultiSpaces[0], " ");
    }

    if (type === "radio" || type === "checkbox") {
      this.state.data[i].text = value;
    } else {
      this.state.data[i].value = value;
    }
    this.setState(this.state, this.onChange);
  };

  addItem = () => {
    const { type } = this.props;

    if (type === "radio" || type === "checkbox") {
      this.state.data.push({
        text: "",
        initialValue: false,
        id: v4(),
      });
    } else {
      this.state.data.push({
        id: v4(),
        value: "",
      });
    }

    this.setState(this.state, this.onChange);
  };

  uploadQuestionsFile = (type) => (e) => {
    const files = [...e.target.files];
    let formData = new FormData();
    formData.append("file", files[0]);
    fetch(global.apiUrl + "/xlsx/parse", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const newItems = data.map((item) => {
          if (type === "list") {
            return { id: v4(), value: item };
          }
          return { text: item, initialValue: false, id: v4() };
        });
        this.setState({ data: newItems }, this.onChange);
      });
  };

  copyItem = (i) => {
    const newItem = _.cloneDeep(this.state.data[i]);
    newItem.id = v4();
    this.state.data.splice(i + 1, 0, newItem);
    this.setState(this.state, this.onChange);
  };

  removeItem = (i) => {
    this.state.data.splice(i, 1);
    this.setState(this.state, this.onChange);
  };

  onDragStart = (e, i) => {
    this.state.draggedItem = i;
    this.state.draggedHtml = e.target.parentNode.parentNode.parentNode;
    this.state.draggedHtml.classList.add("mh-listEditor_item__dragged");
    [...this.state.draggedHtml.parentNode.childNodes].forEach((elem, index) => {
      if (i !== index) {
        elem.classList.add("mh-listEditor_item__moving");
      }
    });
    this.state.dragStartY = e.clientY;
    this.setState(this.state);
  };

  onDragOver = (e) => {
    e.preventDefault();

    if (this.state.draggedHtml) {
      let dif = e.clientY - this.state.dragStartY;
      const elem = this.state.draggedHtml;
      const wrapper = elem.parentNode;
      const wrapperChildren = [...wrapper.childNodes];

      if (elem.offsetTop + dif < 0) dif = 0 - elem.offsetTop;
      if (elem.offsetTop + dif > wrapper.offsetHeight - 28)
        dif = wrapper.offsetHeight - elem.offsetTop - 28;

      const currentY = elem.offsetTop + dif;
      const current = this.state.draggedItem;
      const currentPos = parseInt(currentY / 38);

      wrapperChildren.forEach((i, index) => {
        if (current < currentPos) {
          if (i.offsetTop < currentY && currentY <= i.offsetTop + 38) {
            i.style.transform = "translateY(-38px)";
          } else if (current < index && index <= currentPos) {
            i.style.transform = "translateY(-38px)";
          } else {
            i.style.transform = "translateY(0px)";
          }
        } else {
          if (i.offsetTop <= currentY && currentY < i.offsetTop + 38) {
            i.style.transform = "translateY(38px)";
          } else if (currentPos <= index && index < current) {
            i.style.transform = "translateY(38px)";
          } else {
            i.style.transform = "translateY(0px)";
          }
        }
      });

      elem.style = "transform: translateY(" + dif + "px);z-index:1;";
    }
  };

  onDragOverItem = (e) => {
    e.preventDefault();
  };

  onDragLeave = (e) => {
    e.preventDefault();
  };

  onDragEnd = (e) => {
    e.preventDefault();
    document.querySelectorAll(".mh-listEditor_item").forEach((elem) => {
      elem.style = "";
    });

    this.state.draggedItem = undefined;
    this.state.draggedHtml = undefined;
    this.setState(this.state);
  };

  onDrop = (e, i) => {
    e.preventDefault();
    const index = this.state.draggedItem;
    const item = _.cloneDeep(this.state.data[index]);

    const dif = e.clientY - this.state.dragStartY;
    const elem = this.state.draggedHtml;
    const currentY = elem.offsetTop + dif;
    const newPos = parseInt(currentY / 38);

    this.state.data.splice(index, 1);
    this.state.data.splice(newPos, 0, item);

    this.state.draggedHtml.style = "";
    this.state.draggedHtml.classList.remove("mh-listEditor_item__dragged");
    [...this.state.draggedHtml.parentNode.childNodes].forEach((elem, index) => {
      if (i !== index) {
        elem.classList.remove("mh-listEditor_item__moving");
      }
    });

    this.state.draggedItem = undefined;
    this.state.draggedHtml = undefined;
    this.setState(this.state, this.onChange);
  };

  render = () => {
    let typeBlock = () => "";

    if (this.props.type === "checkbox") {
      typeBlock = (value, i) => (
        <div className="mh-listEditor_type">
          <Checkbox
            value={value ? value : false}
            onChange={(e) => this.onInitialValueChange(e, i)}
          />
        </div>
      );
    }

    if (this.props.type === "radio") {
      typeBlock = (value, i) => (
        <div className="mh-listEditor_type">
          <Radio
            value={value ? value : false}
            onChange={(e) => this.onInitialValueChange(e, i)}
          />
        </div>
      );
    }

    if (this.props.type === "scale") {
      typeBlock = (i) => (
        <div className="mh-listEditor_type">
          <p className="mh-listEditor_typeScale">{i + 1}</p>
        </div>
      );
    }

    if (this.props.type === "list") {
      typeBlock = () => (
        <div className="mh-listEditor_type">
          <p className="mh-listEditor_typeScale">
            <img src={IMAGES.constructor.arrow} alt="" />
          </p>
        </div>
      );
    }

    return (
      <div className="option--menu--block">
        <div className="d-flex align-items-center gap-3">
          <label className="option--menu--label">Вопросы</label>
          {(this.props.type === "radio" ||
            this.props.type === "checkbox" ||
            this.props.type === "list") && (
            <>
              <Button
                variant="link"
                style={{ fontSize: 14, paddingTop: 0, paddingBottom: 0 }}
                onClick={() => this.uploadExcel.current.click()}
              >
                Импорт
              </Button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={this.uploadExcel}
                onChange={this.uploadQuestionsFile(this.props.type)}
                accept=".csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </>
          )}
        </div>
        <div className="mh-listEditor">
          <div
            className="mh-listEditor_list"
            onDragOver={this.onDragOver}
            onDragEnd={this.onDragEnd}
          >
            {this.state.data
              ? this.state.data.map((item, i) =>
                  "initialValue" in item ? (
                    <div
                      className="mh-listEditor_item"
                      key={i}
                      onDragOver={this.onDragOverItem}
                      onDrop={(e) => this.onDrop(e, i)}
                    >
                      {typeBlock(item.initialValue, i)}
                      <input
                        className="mh-listEditor_itemText"
                        placeholder="Ответ..."
                        value={item.text}
                        onChange={(e) => this.onChangeText(e, i)}
                      />
                      <div className="mh-listEditor_itemButtons">
                        <button
                          type="button"
                          className="mh-listEditor_actionButton"
                          onClick={() => this.copyItem(i)}
                        >
                          <img src={IMAGES.thin.copy} alt="" />
                        </button>
                        <button
                          type="button"
                          className="mh-listEditor_actionButton"
                          onClick={() => this.removeItem(i)}
                        >
                          <img src={IMAGES.thin.bin} alt="" />
                        </button>
                        <button
                          type="button"
                          className="mh-listEditor_actionButton"
                          onDragStart={(e) => this.onDragStart(e, i)}
                        >
                          <img src={IMAGES.thin.move} alt="" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="mh-listEditor_item"
                      key={i}
                      onDragOver={this.onDragOverItem}
                      onDrop={(e) => this.onDrop(e, i)}
                    >
                      {typeBlock(i)}
                      <input
                        className="mh-listEditor_itemText"
                        placeholder="Ответ..."
                        value={item.value}
                        onChange={(e) => this.onChangeText(e, i)}
                      />
                      <div className="mh-listEditor_itemButtons">
                        <button
                          type="button"
                          className="mh-listEditor_actionButton"
                          onClick={() => this.copyItem(i)}
                        >
                          <img src={IMAGES.thin.copy} alt="" />
                        </button>
                        <button
                          type="button"
                          className="mh-listEditor_actionButton"
                          onClick={() => this.removeItem(i)}
                        >
                          <img src={IMAGES.thin.bin} alt="" />
                        </button>
                        <button
                          type="button"
                          className="mh-listEditor_actionButton"
                          onDragStart={(e) => this.onDragStart(e, i)}
                        >
                          <img src={IMAGES.thin.move} alt="" />
                        </button>
                      </div>
                    </div>
                  )
                )
              : ""}
          </div>

          <button
            className="mh-listEditor_addButton"
            type="button"
            onClick={this.addItem}
          >
            <img src={IMAGES.constructor.addListItem} alt="" />
            Добавить
          </button>
        </div>
      </div>
    );
  };
}

export default ListEditor;
